import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoadingDlgComponent } from './template/loading.component';
import { DelayDlgComponent } from './template/delay.component';
import { AlertDlgComponent } from './template/alert.component';
import { InfoDlgComponent } from './template/info.component';
import { ConfirmDlgComponent } from './template/confirm.component';
import { GoBackDlgComponent } from './template/goBack.component';
import { GoBackSaveDlgComponent } from './template/goBackSave.component';
import { ComponentFactoryResolver } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Injectable()
export class DialogService {

    private loadingDlg;
    constructor(private modalService: NgbModal, private componentFactoryResolver: ComponentFactoryResolver) {
        this.componentFactoryResolver.resolveComponentFactory<InfoDlgComponent>(InfoDlgComponent);
    }


    /**
     * 加载对话框
     */
     loading = {
        open: (options={}) => {
            this.loadingDlg = this.modalService.open(LoadingDlgComponent, { backdrop: 'static', keyboard: false });
            this.loadingDlg.componentInstance.init(options);
        },
        close: () => {
            if (this.loadingDlg) {
                this.loadingDlg.close();
            }
        },
    };

    /**
     * 消息对话框
     * @param options
     */
     alert(options) {
      const dialog = this.modalService.open(AlertDlgComponent, { backdrop: 'static',keyboard:false });
      dialog.componentInstance.init(options);
    }

    /**
     * 延迟对话框
     */
    delay(options) {
        const dialog = this.modalService.open(DelayDlgComponent, { backdrop: 'static',keyboard:false });
        dialog.componentInstance.init(options);
    }

    /**
     * 信息对话框
     */
    info(options) {
      const dialog = this.modalService.open(InfoDlgComponent, { backdrop: 'static',keyboard:false});
      dialog.componentInstance.init(options);
    }
    

    /**
     * 确认对话框
     */
    confirm(options) {
      const dialog = this.modalService.open(ConfirmDlgComponent, { backdrop: 'static',keyboard:false });
      dialog.componentInstance.init(options);
    }

    
    /**
     * 返回对话框
     */
    goBack(options) {
      const dialog = this.modalService.open(GoBackDlgComponent, { backdrop: 'static' });
      dialog.componentInstance.init(options);
    }

    /**
     * 返回保存对话框
     */
    goBackSave(options) {
      const dialog = this.modalService.open(GoBackSaveDlgComponent, { backdrop: 'static' });
      dialog.componentInstance.init(options);
    }

    /**
     * 自定义对话框，对话框内容通过自定义Componet实现
     */
    custom<T>(params: {
        component: T,
        initComponent?: (componentInstance) => void,
        options?: NgbModalOptions
    }): NgbModalRef {

        const dialog = this.modalService.open(params.component, params.options);

        if (params.initComponent) {
            params.initComponent(dialog.componentInstance);
        }

        return dialog;
    }
}
