import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-goBackSave',
  templateUrl: './goBackSave.component.html',
  styleUrls: ['./goBackSave.component.scss']
})
export class GoBackSaveDlgComponent implements OnInit {
  options={
    title: '提示',
    message: '',
    danger: '',
    onConfirm: () => {},
    onCancel: (res) => {},
    closeButton: true,
    cofirmButton: true,
    cancelButton: true,
    cofirmButtonText: '立即离开',
    cancelButtonText: '继续编辑',
    textX: '',
  };
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }
  init(options) {
    Object.assign(this.options,options)
  }
  confirm() {
    this.activeModal.close();
    this.options.onConfirm();
  }
  cancel(res='') {
    this.activeModal.close();
    this.options.onCancel(res);
  }
}
