import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UserService } from '@/app/core/services/user.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { MessageService } from '@/app/core/services/message.service';
import { DialogService } from '../dialog/dialog.service';
import { BaiduAnalyticsService } from '../../services/baiduAnalytics.service';
/*
   网站头部
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class HeaderComponent implements OnInit {
  @Input() theme; //组模块
  @Input() headerTitle: string = ''; //自定义title
  @Input() href: string; //开发者中心路由
  userInfo: any; //账号信息
  isShow=true;
  pageList:any=['/account/login','/account/register',];
  messageCount: Number;//未读消息
  constructor(
    public userService: UserService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private baiduAnalyticsService: BaiduAnalyticsService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    if (this.userService.user) {//已登录
      this.userInfo = this.userService.user;
      this.queryUnReadCnt();//获取未读消息
    } else {//未登录
      this.activatedRoute.queryParams.subscribe((params: Params) => {//获取参数:论坛那边过来 直接注册||登录
        let forumRegister = params['forum_register'];//论坛注册
        let forumLogin = params['forum_login'];//论坛登录
        if (forumRegister == 1) {
          this.handleRegisterClick();
        } else if (forumLogin == 1) {
          this.handleLogginClick();
        }
      });
    }
  }
  /*
   登录跳转
  */
  handleLogginClick() {
    this.baiduAnalyticsService.trackEvent('首页', '登录');
    // this.router.navigate(['/account/login']);
    window.location.href = './account/login';
  }
  /*
    退出登录
  */
  handleLoggoutClick() {
    this.userService.logout().subscribe(data => {
      // this.router.navigate(['/account/login']);
      window.location.href = './account/login';
    });
  }
  /*
    注册跳转
  */
  handleRegisterClick() {
    this.baiduAnalyticsService.trackEvent('首页', '注册');
    // this.router.navigate(['/account/register']);
    window.location.href = './account/register';
  }
  //获取未读消息数量
  queryUnReadCnt() {
    this.messageService.queryUnReadCnt().subscribe(data => {
      if (data["code"] == 0) {
        this.messageCount = data["data"]['unReadMessageCnt'];
      } else {
        this.dialogService.alert({ message: data["msg"] });
      }
    });
  }
  handleRun() {
    console.log('e');
    window.location.href = './';
  }
}
