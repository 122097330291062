import { Component , OnInit, HostListener} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DialogService } from '@/app/core/template/dialog/dialog.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  subscribeScoll:any;//滚动检查
  constructor(
    private router: Router,
    private dialogService: DialogService,
  ){}
  ngOnInit() {
    // 缓存检测：方案2（判断对应页面才提示刷新）
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getVersion(event.urlAfterRedirects)
      }
    });
    // 区分生产埋点 
    if(environment.production){
      this.loadBaiduSDK('https://hm.baidu.com/hm.js?19454435023452e6b0619dff55fcef77');
    }else{
      this.loadBaiduSDK('https://hm.baidu.com/hm.js?40ff09b0812282bfb7759bc6cbe43ba1');
    }
    //监听滚动条滚动事件:图片懒加载
    this.onWindowScroll();
  }
  
  getVersion(url){
    if(!environment.version)return;
    $.ajax({
      url: `/assets/version/version.json?timestamp=${new Date().getTime()}`,
      type: "GET",
      data: {},
      dataType: 'json',
      success: (res)=>{
        console.log(environment.version)
        console.log(res)
        if(res['version'] == environment.version){
          // 无版本变化
        }else{
          // 有版本变化
          let urls = url.split('?');// 只要路由地址，不要携带参数
          if(urls[0] && res['changeRouter'].includes(urls[0])){
            if(res['version'] != localStorage.getItem('version')){
              this.dialogService.info({
                message: '检测到服务器新版本',
                closeButton:false,
                cancelButton:false,
                cofirmButtonText: '更新',
                onConfirm:()=>{
                  localStorage.setItem('version', res['version']);
                  try {
                    if(window.location.href.indexOf('?') !=-1){
                      window.location.replace(`${window.location.href}&timestamp=${new Date().getTime()}`)
                    }else{
                      window.location.replace(`${window.location.href}?timestamp=${new Date().getTime()}`)
                    }
                  } catch (error) {
                    window.location.reload(true)
                  }
                }
              });
            }
          }
        }
      },
      error:(error)=>{
        console.log(error)
      },	
    });
  }
  //加百度SDK
  loadBaiduSDK(src){
    (function(){
      var hm = document.createElement("script");
      hm.src = src;
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }
  //图片懒加载
  onWindowScroll(){
    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop(); // 当前滚动条距离顶部的高度
      var windowHeight = $(this).height(); // 窗口可视区域的高度
      $('img[name]').each(function() {
        var imgOffset = $(this).offset().top; // 图片距离顶部的高度
        if (imgOffset < scrollTop + windowHeight) {
          $(this).attr('src', $(this).attr('name')); // 加载图片
          $(this).removeAttr('name'); // 删除name属性
        }
      });
    });
  }
  //阻止表单回车提交
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();
  }
}
