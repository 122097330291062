import { MemberService } from '../services/member.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { UserService } from '../services/user.service';
import { DialogService } from '../template/dialog/dialog.service';

@Injectable()
export class AuthGuard implements /*CanLoad,*/ CanActivate {
  members;
  constructor(
    private memberService: MemberService,
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // 首页不需要登录
    if (state.url != '/') {
      return Observable.create((observer: Observer<boolean>) => {
          // 用户状态 status: 1未完善资料 2已完善 3冻结 4等待审核 5未通过审核
          switch (this.userService.user['status']) {
            case 1:// 1未完善资料
              observer.next(false);
              this.dialogService.info({
                message: '您还未完善资料，请先完善资料',
                onConfirm: () => {
                  // this.router.navigate(['/account/data']);
                  window.location.href = './account/data';
                }
              });
              break;
            case 2:// 2已完善
              observer.next(true);
              break;
            case 3:// 3冻结
              observer.next(false);
              // this.router.navigate(['/account/accpuntIntercept']);;
              window.location.href = './account/accpuntIntercept';
              break;
            case 4:// 4等待审核
              observer.next(false);
              // this.router.navigate(['/account/accpuntIntercept']);;
              window.location.href = './account/accpuntIntercept';
              break;
            case 5:// 5未通过审核
              observer.next(false);
              this.dialogService.info({
                message: '您的开发者账号资料审核未通过，请重新编辑后再次提交',
                onConfirm: () => {
                  // this.router.navigate(['/account/data']);
                  window.location.href = './account/data';
                }
              });
              break;
            default:
              observer.next(false);
              break;
          }
      });
    }
  }
}
