import { async } from '@angular/core/testing';
import { MemberService } from '../services/member.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { UserService } from '../services/user.service';
import { DialogService } from '../template/dialog/dialog.service';

@Injectable()
export class WhiteListGuard implements /*CanLoad,*/ CanActivate {
  members;
  constructor(
    private memberService: MemberService,
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // 首页不需要登录
    if (state.url != '/') {
      return Observable.create((observer: Observer<boolean>) => {
        if(this.userService.user['maintenanceInfo']){
          // 停机类型 type: 1全部 2快游戏 3手机快应用 车机快应用 5手表快应用
          switch (this.userService.user['maintenanceInfo']['type']) {
            case '1':// 1全部
              if(state.url.indexOf('myCenter') != -1){
                observer.next(false);
                this.openDialog();
              }else{
                observer.next(true);
              }
              break;
            case '2':// 快游戏
              if(state.url.indexOf('gameManage') != -1){
                observer.next(false);
                this.openDialog('/myCenter');
              }else{
                observer.next(true);
              }
              break;
            case '3':// 手机快应用
              if(state.url.indexOf('appManage') != -1 || state.url.indexOf('thirdPartyService') != -1 ){
                observer.next(false);
                this.openDialog('/myCenter');
              }else{
                observer.next(true);
              }
              break;
            case '4':// 车机快应用
              if(state.url.indexOf('carAppManage') != -1){
                observer.next(false);
                this.openDialog('/myCenter');
              }else{
                observer.next(true);
              }
              break;
            case '5':// 手表快应用
              if(state.url.indexOf('watchAppManage') != -1){
                observer.next(false);
                this.openDialog('/myCenter');
              }else{
                observer.next(true);
              }
              break;
            default:
              observer.next(true);
              break;
          }
        }else{
          observer.next(true);
        }
      });
    }
  }
  openDialog(path='/'){
    this.dialogService.info({
      title: this.userService.user['maintenanceInfo']['title'],
      message: this.userService.user['maintenanceInfo']['content'],
      textX:'text-left',
      onConfirm: () => {
        console.log(path === '/');
        if (path === '/') {
          window.location.href = './';
        } else {
          this.router.navigate([path]);
        }
      }
    });
  }
}
