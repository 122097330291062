import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { UserService } from '../services/user.service';
import { DialogService } from '../template/dialog/dialog.service';
// import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserInfoGuard implements /*CanLoad,*/ CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService,
    // private cookieService: CookieService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return Observable.create((observer: Observer<boolean>) => {
      /*页面启动 必须先拿登录信息*/
      if (route.queryParams.ideToken && route.queryParams.sign && route.queryParams.timestamp) {//IDE跳转过来自动登录
        this.userService.ideLoggin(
          encodeURIComponent(route.queryParams.ideToken),
          encodeURIComponent(route.queryParams.sign),
          encodeURIComponent(route.queryParams.timestamp),
        ).subscribe((data) => {
          if (data['code'] === 0) {
            //获取用户信息
            this.userService.getUserInfoRemote().subscribe(user => {
              this.router.navigate(['/myCenter/quickAppCenter']);
            })
          } else {
            // this.router.navigate(['/']);
            window.location.href = './'
          }
        });
        observer.next(false);
      } else {
        //获取用户信息
        this.userService.getUserInfoRemote().subscribe(user => {
          if(user){
            // 用于投票
            // this.cookieService.set("uuid",user['uuid'],new Date(new Date().getTime() +  30*24*60*60*1000));// cookie过期时间30天
          }
          observer.next(true);
        });
      }
    });
  }
}
