import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from "./pipe/safe-html.pipe";
import { DialogService } from './dialog.service';
import { AlertDlgComponent } from './template/alert.component';
import { DelayDlgComponent } from './template/delay.component';
import { InfoDlgComponent } from './template/info.component';
import { ConfirmDlgComponent } from './template/confirm.component';
import { LoadingDlgComponent } from './template/loading.component';
import { GoBackDlgComponent } from './template/goBack.component';
import { GoBackSaveDlgComponent } from './template/goBackSave.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SafeHtmlPipe,
    AlertDlgComponent,
    DelayDlgComponent,
    InfoDlgComponent,
    ConfirmDlgComponent,
    LoadingDlgComponent,
    GoBackDlgComponent,
    GoBackSaveDlgComponent,
  ],
  exports: [
    SafeHtmlPipe,
    AlertDlgComponent,
    DelayDlgComponent,
    InfoDlgComponent,
    ConfirmDlgComponent,
    LoadingDlgComponent,
    GoBackDlgComponent,
    GoBackSaveDlgComponent,
  ],
  providers: [
    DialogService,
  ],
  entryComponents: [
    AlertDlgComponent,
    DelayDlgComponent,
    InfoDlgComponent,
    ConfirmDlgComponent,
    LoadingDlgComponent,
    GoBackDlgComponent,
    GoBackSaveDlgComponent,
  ],
})
export class DialogModule { }
