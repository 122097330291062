import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceDirective {
  @Output() appDebounceClick = new EventEmitter(); // 定义一个输出事件，用于传递点击事件
  private debounceSubject: Subject<void>;
  private debounceTime: number = 300; // 默认的防抖时间，可以根据需要修改
 
  constructor() {
    this.debounceSubject = new Subject<void>();
    this.debounceSubject.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(() => {
      this.appDebounceClick.emit();
    });
  }
 
  @HostListener('click')
  clickEvent() {
    this.debounceSubject.next();
  }
}
