import { BaiduAnalyticsDirective } from './directive/baiduAnalytics.directive';
import { DefaultHomeComponent } from '././template/header/template/defaultHome/defaultHome.component';
import { DeveloperSupportComponent } from '././template/header/template/developerSupport/developerSupport.component';
import { DefaultQuickGameComponent } from '././template/header/template/defaultQuickGame/defaultQuickGame.component';
import { RouterModule } from '@angular/router';
import { DeveloperCenterComponent } from '././template/header/template/developerCenter/developerCenter.component';
import { HeaderComponent } from './template/header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwardsActHeaderComponent } from './template/header/template/awardsActHeader/awardsActHeader.component';
import { AwardsAct2HeaderComponent } from './template/header/template/awardsAct2Header/awardsAct2Header.component';
import { AwardsAct2NavComponent} from './template/header/template/awardsAct2Nav/awardsAct2Nav.component'
import { DocumentCenterHeaderComponent } from './template/header/template/documentCenterHeader/documentCenterHeader.component';
import { FooterComponent } from '@/app/core/template/footer/footer.component';
import { ICPComponent } from './template/ICP/ICP.component';
import { DefaultICPComponent } from './template/ICP/template/defaultICP/defaultICP.component';
import { DocumentCenterICPComponent } from './template/ICP/template/documentCenterICP/documentCenterICP.component';
import { FlowSupportHeaderComponent } from './template/header/template/flowSupportHeader/flowSupportHeader.component';
import { DefaultFilingComponent } from './template/header/template/defaultFiling/defaultFiling.component';
import { CrumbsComponent } from './template/crumbs/crumbs.component';
import { LookImageDirective } from './directive/lookImage.directive';
import { FormsModule } from '@angular/forms';
import { AddressLengthDirective } from './directive/addressLength.directive';
import { AllTrimDirective } from './directive/allTrim.directive';
import { TrimDirective } from './directive/trim.directive';
import { CheckboxLengthDirective } from './directive/checkboxLength.directive';
import { ImgLengthDirective } from './directive/imgLength.directive';
import { ImgLength2Directive } from './directive/imgLength2.directive';
import { IsRepeatDirective } from './directive/isRepeat.directive';
import { DefaultCarQuickAppComponent } from './template/header/template/defaultCarQuickApp/defaultCarQuickApp.component';
import { DefaultWatchQuickAppComponent } from './template/header/template/defaultWatchQuickApp/defaultWatchQuickApp.component';
import { DefaultXrQuickAppComponent } from './template/header/template/defaultXrQuickApp/defaultXrQuickApp.component';
import { FormContentComponent } from './template/form/formContent/formContent.component';
import { FormFullComponent } from './template/form/formFull/formFull.component';
import { FormItemComponent } from './template/form/formItem/formItem.component';
import { FormViewComponent } from './template/form/formView/formView.component';
import { AppProgressBoxComponent } from './template/progressBox/appProgressBox/appProgressBox.component';
import { GameProgressBoxComponent } from './template/progressBox/gameProgressBox/gameProgressBox.component';
import { FilingProgressBoxComponent } from './template/progressBox/filingProgressBox/filingProgressBox.component';
import { IsIdCardDirective } from '@/app/core/directive/isIdCard.directive';
import { IdCardDateDirective } from '@/app/core/directive/idCardDate.directive';
import { ServicesObjectDirective } from '@/app/core/directive/servicesObject.directive';
import { DisabledFormDirective } from '@/app/core/directive/disabledForm.directive';
import { IsEqualDirective } from '@/app/core/directive/isEqual.directive';
import { CaptchaAsyncDirective } from '@/app/core/directive/inCaptcha.directive';
import { IsRepeatFzrDirective } from '@/app/core/directive/isRepeatFzr.directive';
import { IsJstxZhDirective } from '@/app/core/directive/isJstxZh.directive';
import { DebounceDirective } from '@/app/core/directive/debounce.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [
    //公共组件
    HeaderComponent,
    DeveloperCenterComponent,
    DefaultCarQuickAppComponent,
    DefaultWatchQuickAppComponent,
    DefaultXrQuickAppComponent,
    DefaultQuickGameComponent,
    DeveloperSupportComponent,
    DefaultHomeComponent,
    AwardsActHeaderComponent,
    AwardsAct2HeaderComponent,
    AwardsAct2NavComponent,
    DocumentCenterHeaderComponent,
    FooterComponent,
    ICPComponent,
    DefaultICPComponent,
    DocumentCenterICPComponent,
    FlowSupportHeaderComponent,
    DefaultFilingComponent,
    // 功能组件
    CrumbsComponent,
    // form
    FormContentComponent,
    FormFullComponent,
    FormItemComponent,
    FormViewComponent,
    //进度条
    AppProgressBoxComponent,
    GameProgressBoxComponent,
    FilingProgressBoxComponent,
    //公共指令
    AddressLengthDirective,
    AllTrimDirective,
    BaiduAnalyticsDirective,
    LookImageDirective,
    TrimDirective,
	  CheckboxLengthDirective,
    ImgLengthDirective,
    ImgLength2Directive,
    IsRepeatDirective,
    IsIdCardDirective,
    IdCardDateDirective,
    ServicesObjectDirective,
    DisabledFormDirective,
    IsEqualDirective,
    CaptchaAsyncDirective,
    IsRepeatFzrDirective,
    IsJstxZhDirective,
    DebounceDirective,
  ],
  exports:[
    //公共组件
    HeaderComponent,
    DeveloperCenterComponent,
    DefaultCarQuickAppComponent,
    DefaultWatchQuickAppComponent,
    DefaultXrQuickAppComponent,
    DefaultQuickGameComponent,
    DeveloperSupportComponent,
    AwardsActHeaderComponent,
    AwardsAct2HeaderComponent,
    AwardsAct2NavComponent,
    DocumentCenterHeaderComponent,
    FooterComponent,
    ICPComponent,
    DefaultICPComponent,
    DocumentCenterICPComponent,
    FlowSupportHeaderComponent,
    DefaultFilingComponent,
    DefaultHomeComponent,
    // 功能组件
    CrumbsComponent,
    // form
    FormContentComponent,
    FormFullComponent,
    FormItemComponent,
    FormViewComponent,
    //进度条
    AppProgressBoxComponent,
    GameProgressBoxComponent,
    FilingProgressBoxComponent,
    //公共指令
    AddressLengthDirective,
    AllTrimDirective,
    BaiduAnalyticsDirective,
    LookImageDirective,
    TrimDirective,
    CheckboxLengthDirective,
    ImgLengthDirective,
    ImgLength2Directive,
    IsRepeatDirective,
    IsIdCardDirective,
    IdCardDateDirective,
    ServicesObjectDirective,
    DisabledFormDirective,
    IsEqualDirective,
    CaptchaAsyncDirective,
    IsRepeatFzrDirective,
    IsJstxZhDirective,
    DebounceDirective,
  ],
})
export class customCommonModule { }
